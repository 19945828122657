import { ReactElement, useEffect, useState } from "react";
import MainLayout from "../components/layout/main";
import { GetServerSideProps } from "next";
import { useAuth } from "../hooks/auth";
import Unprotected from "../server/unprotected";
import classNames from "../util/classNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { useApi } from "../hooks/api";
import { Activity, ActivityType } from "../model/activity";
import { Review } from "../model/review";
import { PrepCenter, PrepCenterLocation } from "../model/prepCenter";
import Spinner from "../components/spinner";
import Link from "next/link";
import Image from "next/image";
import CreatePrepCenterModal from "../components/modal/createPrepCenter";
import { useRouter } from "next/router";

const randomGradientBg = (): string => {
  const gradients: string[] = [
    "from-cyan-500 to-blue-500",
    "from-sky-500 to-indigo-500",
    "from-blue-500 to-cyan-500",
    "from-indigo-500 to-sky-500",
    // "from-violet-500 to-fuchsia-500",
    // "from-purple-500 to-pink-500",
  ];

  return gradients[Math.floor(Math.random() * gradients.length)];
};

const getInitials = (review: Review): string => {
  if (review.reviewerFirstName && review.reviewerLastName) {
    return (
      review.reviewerFirstName.charAt(0).toUpperCase() +
      review.reviewerLastName.charAt(0).toUpperCase()
    );
  }

  return review.reviewerName?.slice(0, 2).toUpperCase() ?? "PC";
};

const getLocationInfo = (locations: PrepCenterLocation[] | null): string => {
  if (!locations || locations.length === 0) return "No locations listed";

  if (locations.length === 1)
    return `Based in ${locations[0].city ? locations[0].city + ", " : ""}${
      locations[0].state
    }, ${locations[0].country}`;

  return `${locations.length} Locations`;
};

const loadingState = (key: number) => (
  <li key={key} className="col-span-1 bg-white rounded-lg shadow">
    <div className="animate-pulse w-full flex items-center justify-between p-6 space-x-6">
      <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-slate-300"></span>

      <div className="flex-1 truncate">
        <div className="space-y-3">
          <div className="h-2 w-28 bg-slate-300 rounded"></div>
          <div className="h-2 w-36 bg-slate-300 rounded"></div>
        </div>
      </div>
    </div>

    <div className="animate-pulse relative h-24 justify-center items-center flex">
      <Spinner className="mr-2.5 fill-slate-100 w-14 h-14 z-10"></Spinner>

      <div
        className={classNames(
          "absolute w-full h-full justify-center items-center flex bg-slate-300"
        )}
      ></div>
    </div>

    <div className="animate-pulse p-6">
      <div className="h-2 w-36 bg-slate-300 rounded mb-2"></div>

      <div className="flex items-center mt-1">
        {[0, 1, 2, 3, 4].map((rating) => (
          <StarIcon
            key={rating}
            className="text-slate-300 h-5 w-5 flex-shrink-0"
            aria-hidden="true"
          />
        ))}
      </div>
      <p className="sr-only">{4} out of 5 stars</p>

      <div className="mt-4 prose prose-sm max-w-none text-gray-500">
        <div className="h-2 bg-slate-300 rounded"></div>
      </div>
    </div>
  </li>
);

export default function Home() {
  const { authUser, dbUser, prepCenter, getOrCreateUser } = useAuth();
  const { listActivity } = useApi();
  const router = useRouter();
  const [activityCollection, setActivityCollection] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);
  const [activityError, setActivityError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    async function doListActivity() {
      const { status, activityCollection, errorMessage } = await listActivity();
      if (status === 200) {
        setActivityCollection(activityCollection as Activity[]);
      } else {
        setActivityError(errorMessage as string);
      }
      setLoading(false);
    }
    doListActivity();
  }, [listActivity, setLoading, setActivityCollection]);

  return (
    <>
      <header className="bg-vendrive-base pt-8 pb-40">
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-28 lg:px-8">
          <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            <span className="block text-white">Find the most reputable</span>
            <span className="block text-vendrive-200">Amazon Prep Centers</span>
          </h1>

          <p className="mt-6 max-w-lg mx-auto text-center text-xl text-vendrive-200 sm:max-w-3xl">
            We take the guesswork out of finding the best prep center for your
            business by crowd-sourcing reviews and ratings from FBA sellers like
            you all around the world.
          </p>

          <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
            <div
              className={classNames(
                "space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:gap-5",
                authUser && prepCenter ? "" : "sm:grid-cols-2"
              )}
            >
              <Link href="/search">
                <a className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-vendrive-700 bg-white hover:bg-vendrive-50 sm:px-16">
                  Find a prep center
                </a>
              </Link>
              {!authUser ? (
                <Link href="/signup">
                  <a className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-vendrive-500 bg-opacity-60 hover:bg-opacity-70 sm:px-16">
                    Add your prep center
                  </a>
                </Link>
              ) : authUser && !prepCenter ? (
                <button
                  onClick={() => setModalOpen(true)}
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-vendrive-500 bg-opacity-60 hover:bg-opacity-70 sm:px-16 w-full"
                >
                  Add your prep center
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </header>

      <main className="-mt-32">
        {/*<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Replace with your content */}
        {/*
            <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
              <h2 className="text-center py-2 text-3xl font-extrabold tracking-tight bg-gradient-to-r from-vendrive-300 to-vendrive-900 bg-clip-text text-transparent">
                Recent activity
              </h2>
              <div className="mt-6 h-96 border-4 border-dashed border-gray-200 rounded-lg" />
            </div>
            {/* /End replace */}
        {/*
          </div>*/}
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto px-5 sm:px-6 lg:px-8"
        >
          {loading
            ? [0, 1, 2].map((key) => loadingState(key))
            : activityCollection.map((activity) => {
                switch (activity.type) {
                  case ActivityType.Review:
                    let review = activity.object as Review;
                    return (
                      <li
                        key={review.userId + review.prepCenterId}
                        className="col-span-1 bg-white rounded-lg shadow"
                      >
                        <Link href={`/p/${review.prepCenterId}`} passHref>
                          <a className="flex flex-col h-full">
                            <div className="w-full flex items-center justify-between p-6 space-x-6">
                              <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                <span className="font-medium leading-none text-white">
                                  {getInitials(review)}
                                </span>
                              </span>

                              <div className="flex-1 truncate">
                                <div className="flex items-center space-x-3">
                                  <h3 className="text-gray-900 text-sm font-medium truncate">
                                    {review.reviewerFirstName
                                      ? `${review.reviewerFirstName}${
                                          review.reviewerLastName
                                            ? ` ${review.reviewerLastName.charAt(
                                                0
                                              )}.`
                                            : ""
                                        }`
                                      : review.reviewerName}
                                  </h3>
                                </div>

                                <p className="text-gray-500 text-sm truncate">
                                  Wrote a review
                                </p>
                              </div>
                            </div>

                            <div className="relative h-24 justify-center items-center flex">
                              {review.prepCenter?.logo ? (
                                <div className="z-20 h-24 w-24 rounded-md bg-white p-1 shadow-lg mt-10">
                                  <div className="relative flex h-full w-full rounded-sm">
                                    <Image
                                      src={review.prepCenter?.logo}
                                      alt={review.prepCenter?.name}
                                      layout="fill"
                                      objectFit="contain"
                                      className="rounded-sm"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <span className="text-white text-xl font-extrabold tracking-tight z-10">
                                  {review.prepCenter?.name}
                                </span>
                              )}

                              <div
                                className={classNames(
                                  "absolute w-full h-full bg-gradient-to-r justify-center items-center flex bg-center bg-no-repeat bg-cover",
                                  randomGradientBg()
                                )}
                              ></div>

                              <div
                                style={
                                  review.prepCenter?.banner
                                    ? {
                                        backgroundImage:
                                          `url("${review.prepCenter?.banner}")` ??
                                          undefined,
                                      }
                                    : undefined
                                }
                                className="absolute opacity-50 w-full h-full bg-center bg-no-repeat bg-cover"
                              ></div>
                            </div>

                            <div
                              className={
                                review.prepCenter?.logo
                                  ? "px-6 pb-6 pt-10"
                                  : "p-6"
                              }
                            >
                              <div className="text-base font-bold text-gray-500 hover:text-gray-900">
                                {review.prepCenter?.name}
                              </div>

                              <div className="flex items-center mt-1">
                                {[0, 1, 2, 3, 4].map((rating) => (
                                  <StarIcon
                                    key={rating}
                                    className={classNames(
                                      review.rating > rating
                                        ? "text-yellow-400"
                                        : "text-gray-300",
                                      "h-5 w-5 flex-shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                ))}
                              </div>
                              <p className="sr-only">
                                {review.rating} out of 5 stars
                              </p>

                              <div className="mt-4 prose prose-sm max-w-none text-gray-500">
                                <p className="line-clamp-3">{review.review}</p>
                              </div>
                            </div>
                          </a>
                        </Link>
                      </li>
                    );
                  case ActivityType.PrepCenter:
                    let prepCenter = activity.object as PrepCenter;
                    return (
                      <li
                        key={prepCenter.id}
                        className="col-span-1 bg-white rounded-lg shadow"
                      >
                        <Link href={`/p/${prepCenter.id}`} passHref>
                          <a className="flex flex-col h-full">
                            <div className="w-full flex items-center justify-between p-6 space-x-6">
                              <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-green-600">
                                <FontAwesomeIcon
                                  icon={["fad", "sparkles"]}
                                  className="text-xl h-5 w-5 text-white"
                                />
                              </span>

                              <div className="flex-1 truncate">
                                <div className="flex items-center space-x-3">
                                  <h3 className="text-gray-900 text-sm font-medium truncate">
                                    {prepCenter.name}
                                  </h3>
                                </div>

                                <p className="text-gray-500 text-sm truncate">
                                  Newly added prep center
                                </p>
                              </div>
                            </div>

                            <div className="relative h-24 justify-center items-center flex">
                              {prepCenter.logo ? (
                                <div className="z-20 h-24 w-24 rounded-md bg-white p-1 shadow-lg mt-10">
                                  <div className="relative flex h-full w-full rounded-sm">
                                    <Image
                                      src={prepCenter?.logo}
                                      alt={prepCenter?.name}
                                      layout="fill"
                                      objectFit="contain"
                                      className="rounded-sm"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <span className="text-white text-xl font-extrabold tracking-tight z-10">
                                  {prepCenter.name}
                                </span>
                              )}

                              <div
                                className={classNames(
                                  "absolute w-full h-full bg-gradient-to-r justify-center items-center flex bg-center bg-no-repeat bg-cover",
                                  randomGradientBg()
                                )}
                              ></div>

                              <div
                                style={
                                  prepCenter.banner
                                    ? {
                                        backgroundImage:
                                          `url("${prepCenter.banner}")` ??
                                          undefined,
                                      }
                                    : undefined
                                }
                                className="absolute opacity-50 w-full h-full bg-center bg-no-repeat bg-cover"
                              ></div>
                            </div>

                            <div
                              className={
                                prepCenter.logo ? "px-6 pb-6 pt-10" : "p-6"
                              }
                            >
                              <div className="text-base font-bold text-gray-500 hover:text-gray-900">
                                {prepCenter.name}
                              </div>

                              <div className="flex items-center mt-1 text-gray-700">
                                {getLocationInfo(prepCenter.locations)}
                              </div>

                              <div className="mt-4 prose prose-sm max-w-none text-gray-500">
                                <p className="line-clamp-3">
                                  {prepCenter.description
                                    ? prepCenter.description
                                    : prepCenter.tagline}
                                </p>
                              </div>
                            </div>
                          </a>
                        </Link>
                      </li>
                    );
                  default:
                    return (
                      <li
                        key={activity.object.created_at.toISOString()}
                        className="col-span-1 bg-white rounded-lg shadow"
                      >
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                          <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                            <span className="font-medium leading-none text-white">
                              !
                            </span>
                          </span>

                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 text-sm font-medium truncate">
                                Unsupported activity type
                              </h3>
                            </div>

                            <p className="text-gray-500 text-sm truncate">
                              Your app may be awaiting an update.
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                }
              })}

          {/* Link to directory */}
          <li
            className={
              loading
                ? "hidden"
                : "col-span-1 bg-white hover:bg-gray-50 rounded-lg shadow"
            }
          >
            <Link href="/search" passHref>
              <a className="flex flex-col h-full justify-center">
                <div className="px-6 py-16">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <MapIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Many more in our map!
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Explore all of the different prep centers listed in
                          this directory in a map view.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          </li>
        </ul>

        <CreatePrepCenterModal
          open={modalOpen}
          setOpen={setModalOpen}
          onCreate={(prepCenter) => {
            getOrCreateUser(true);
            if (dbUser?.is_admin && prepCenter) {
              router.push(`/manage/${prepCenter?.id}`);
            }
          }}
        />
      </main>
    </>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>;
};
